.TopSection-module__XtGxAq__top-section {
  white-space: nowrap;
  height: 100%;
  padding: 15px;
}

.TopSection-module__XtGxAq__top-section__dropdown, .TopSection-module__XtGxAq__top-section__radio, .TopSection-module__XtGxAq__top-section__arrow {
  display: none;
}

.TopSection-module__XtGxAq__top-section__live {
  fill: var(--icons-red);
  margin-right: 5px;
}

.TopSection-module__XtGxAq__top-section__container {
  height: 100%;
  color: var(--text-primary);
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 1025px) {
  .TopSection-module__XtGxAq__top-section:hover .TopSection-module__XtGxAq__top-section__dropdown {
    display: flex;
  }

  .TopSection-module__XtGxAq__top-section__container:hover {
    color: var(--text-link-active);
  }
}

@media (max-width: 1024px) {
  .TopSection-module__XtGxAq__top-section {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    display: flex;
  }

  .TopSection-module__XtGxAq__top-section__arrow {
    fill: var(--icons-primary);
    display: block;
  }

  .TopSection-module__XtGxAq__top-section__radio:checked ~ .TopSection-module__XtGxAq__top-section__dropdown, .TopSection-module__XtGxAq__top-section__radio:checked ~ slot > .TopSection-module__XtGxAq__top-section__dropdown {
    display: flex;
  }

  .TopSection-module__XtGxAq__top-section__radio:checked ~ label .TopSection-module__XtGxAq__top-section__container {
    color: var(--text-link-active);
  }

  .TopSection-module__XtGxAq__top-section__radio:checked ~ label .TopSection-module__XtGxAq__top-section__arrow {
    fill: var(--icons-active);
  }

  .TopSection-module__XtGxAq__top-section__container {
    width: 100%;
    color: var(--text-primary);
    border-bottom: 1px solid var(--line-line);
    justify-content: space-between;
    padding: 15px;
    display: flex;
  }

  .TopSection-module__XtGxAq__top-section__container--with-subsections {
    pointer-events: none;
  }

  .TopSection-module__XtGxAq__top-section__container--with-live-icon {
    justify-content: flex-start;
  }

  .TopSection-module__XtGxAq__top-section__dropdown {
    display: none;
  }
}

